import { h } from 'preact';
import { Contact } from 'ui/components/contact/contact';
import { TextRegular } from 'ui/components/typography/typography';
import styles from './intro.module.css';

export const Intro = () => (
  <div className={styles.container}>
    <TextRegular color="white" className={styles.paragraph}>
      Defined by a passion for innovation and exploration, and our willingness to jump down rabbit
      holes, Systematic Trading Associates is a Sydney-based proprietary trading firm with over 15
      years' experience.
    </TextRegular>
    <TextRegular color="white">
      In this often-opaque industry, we believe in collaboration and sharing success which, along
      with our expertise and rich data culture, keeps us in the vangard of automation and
      machine-learning.
    </TextRegular>
    <Contact />
  </div>
);
