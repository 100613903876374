import { h } from 'preact';
import classNames from 'classnames';
import styles from './loading.module.css';

export const Loading = ({
  aspectRatio,
  className,
}: {
  aspectRatio: number;
  className?: string;
}) => (
  <div className={classNames(styles.loading, className)}>
    <div style={{ paddingBottom: `${aspectRatio * 100}%` }}></div>
  </div>
);
