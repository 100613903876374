import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Picture } from '../picture/picture';
import styles from './video.module.css';

const VideoIntro = ({
  src,
  aspectRatio,
  fallbackImageSrc,
  enableLooping,
}: {
  src: string;
  aspectRatio: number;
  fallbackImageSrc: string;
  enableLooping: () => void;
}) => {
  // Wheder to show video or a picture
  const [showVideo, setShowVideo] = useState<boolean>(true);

  // We assume autoplay is disabled
  const [hasLoadStarted, setHasLoadStarted] = useState<boolean>(false);

  // Only if we have fired onPlay do we know autoplay is enabled
  const onPlay = () => {
    setHasLoadStarted(true);
  };

  // After 300ms, lets see if the video has started. If NOT, render the picture.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!hasLoadStarted) {
        setShowVideo(false);
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [setShowVideo, setHasLoadStarted, hasLoadStarted]);

  // After the video has ended, lets enable the loop
  const onEnded = () => {
    enableLooping();
  };

  return !showVideo ? (
    <Picture aspectRatio={aspectRatio} src={fallbackImageSrc} />
  ) : (
    <video
      src={src}
      playsInline
      muted
      autoPlay
      onEnded={onEnded}
      onPlay={onPlay}
      className={styles.video}
    />
  );
};

export const Video = ({
  aspectRatio,
  introSrc,
  loopSrc,
  fallbackImageSrc,
}: {
  aspectRatio: number;
  introSrc: string;
  loopSrc: string;
  fallbackImageSrc: string;
}) => {
  const [isLooping, setIsLooping] = useState<boolean>(false);

  const enableLoopingHandler = () => setIsLooping(true);

  return (
    <div className={styles.container} style={{ paddingBottom: `${aspectRatio * 100}%` }}>
      <div className={styles.asset}>
        <VideoIntro
          src={introSrc}
          aspectRatio={aspectRatio}
          fallbackImageSrc={fallbackImageSrc}
          enableLooping={enableLoopingHandler}
        />
      </div>
      <div className={styles.asset}>
        <video
          src={isLooping ? loopSrc : undefined}
          playsInline
          muted
          autoPlay
          loop
          className={styles.video}
        />
      </div>
    </div>
  );
};
