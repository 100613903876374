import classNames from 'classnames';
import { h } from 'preact';
import styles from './container.module.css';

type ContainerProps = {
  children?: any;
  className?: string;
};

export const Container = ({ children, className }: ContainerProps) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};
