import { h } from 'preact';
import { Container } from 'ui/components/container/container';
import { LogoWide } from 'ui/components/logo/logo';
import { TextRegularAlt } from 'ui/components/typography/typography';
import styles from './footer.module.css';

export const Footer = ({ siteTitle }: { siteTitle: string }) => {
  const year = new Date().getFullYear();
  return (
    <Container className={styles.container}>
      <div className={styles.footer}>
        <LogoWide />
        <div className={styles.copyright}>
          <TextRegularAlt color="navy">
            &copy; {year} {siteTitle}
          </TextRegularAlt>
        </div>
      </div>
    </Container>
  );
};
