import { FunctionalComponent, h } from 'preact';
import { Footer as FooterImpl } from 'ui/components/footer/footer';
import { Panels } from 'ui/components/panels/panels';
import { Grid } from 'ui/components/grid/grid';

import './index.css';

const title = 'Systematic Trading Associates';

const Footer = () => <FooterImpl siteTitle={title} />;

export const App: FunctionalComponent = () => <Panels Grid={Grid} Footer={Footer} />;
