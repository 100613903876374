import { h } from 'preact';
import { Container } from 'ui/components/container/container';
import { Intro } from 'ui/components/intro/intro';
import { Hero } from 'ui/components/hero/hero';
import styles from './grid.module.css';

export const Grid = () => (
  <Container className={styles.container}>
    <div className={styles.grid}>
      <div className={styles.firstColumn}>
        <Hero />
      </div>
      <div className={styles.secondColumn}>
        <Intro />
      </div>
    </div>
  </Container>
);
