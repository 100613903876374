import { h, ComponentType } from 'preact';
import styles from './panels.module.css';

export const Panels = ({ Grid, Footer }: { Grid: ComponentType; Footer: ComponentType }) => (
  <div className={styles.container}>
    <div className={styles.grid}>
      <div className={styles.gridWrapper}>
        <Grid />
      </div>
    </div>
    <div className={styles.footer}>
      <Footer />
    </div>
  </div>
);
