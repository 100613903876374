import { h, Component } from 'preact';
import { Logo } from 'ui/components/logo/logo';
import { Video } from 'ui/components/video/video';
import { Loading } from '../loading/loading';
import { Picture } from '../picture/picture';
import styles from './hero.module.css';

function prefetchAsset(url: string) {
  return new Promise<Blob>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url);
    request.responseType = 'blob';
    request.onload = function () {
      // Check if request status code was successfull.
      if (request.status === 200) {
        // Resolve with request response.
        const response = request.response as Blob;
        resolve(response);
      } else {
        // Otherwise reject with a meaningfull message.
        reject(new Error(request.statusText));
      }
    };
    // Handle any network errors.
    request.onerror = function () {
      reject(new Error('Network error'));
    };
    // Send request.
    request.send();
  });
}

type HeroState = {
  status: 'loading' | 'video' | 'image';
};

const getColorDepth = (fileName: string) => {
  if (typeof window === 'undefined') {
    return '';
  }
  switch (screen.colorDepth) {
    case 24:
      return `${fileName}-24.mp4`;
    case 32:
      return `${fileName}-32.mp4`;
    default:
      return `${fileName}.mp4`;
  }
};

export class Hero extends Component<{}, HeroState> {
  private readonly introSrc = `./assets/${getColorDepth('intro')}`;
  private readonly loopSrc = `./assets/${getColorDepth('loop')}`;
  private readonly fallbackImageSrc = './assets/cube.png';
  private readonly aspectRatio = 1.1454545455;

  constructor() {
    super();
    this.state = { status: 'loading' };
  }

  componentDidMount() {
    this.fetchVideos();
  }

  async fetchVideos() {
    try {
      await Promise.all([
        prefetchAsset(this.introSrc),
        prefetchAsset(this.loopSrc),
        prefetchAsset(this.fallbackImageSrc),
      ]);
      this.setState({ status: 'video' });
    } catch (error) {
      console.log('an error occured: ' + error);
      this.setState({ status: 'image' });
    }
  }

  renderAsset() {
    switch (this.state.status) {
      case 'image':
        return <Picture aspectRatio={this.aspectRatio} src={this.fallbackImageSrc} />;
      case 'video':
        return (
          <Video
            aspectRatio={this.aspectRatio}
            introSrc={this.introSrc}
            loopSrc={this.loopSrc}
            fallbackImageSrc={this.fallbackImageSrc}
          />
        );
      default:
        return <Loading aspectRatio={this.aspectRatio} />;
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.assetContainer}>
          <div className={styles.asset}>{this.renderAsset()}</div>
        </div>
      </div>
    );
  }
}
