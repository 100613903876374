import { h } from 'preact';
import { TextPriority1, TextSub1 } from '../typography/typography';
import styles from './contact.module.css';

export const Contact = () => (
  <div className={styles.container}>
    <TextPriority1 color="white">Get in Touch</TextPriority1>
    <div className={styles.connect}>
      <a href="mailto:hugh@sys.trade" className={styles.cta}>
        <TextSub1 tagName="span">Email Hugh</TextSub1>
      </a>
      <a href="mailto:antoine@sys.trade" className={styles.cta}>
        <TextSub1 tagName="span">Email Antoine</TextSub1>
      </a>
      <a
        href="https://www.linkedin.com/company/systematic-trading-associates/"
        target="_blank"
        rel="noreferrer"
        className={styles.cta}
      >
        <TextSub1 tagName="span">Connect on LinkedIn</TextSub1>
      </a>
    </div>
  </div>
);
