import { h } from 'preact';
import styles from './logo.module.css';
import logoAssetWide from './logoWide.svg';
import logo from './logo.svg';

export const LogoWide = () => (
  <div className={styles.logoWide}>
    <img src={logoAssetWide} className={styles.img} />
  </div>
);

export const Logo = () => (
  <div className={styles.logo}>
    <img src={logo} className={styles.img} />
  </div>
);
