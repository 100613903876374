import { h } from 'preact';
import classNames from 'classnames';
import styles from './typography.module.css';

type AllowedTagNames = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
type AllowedColors = 'navy' | 'white';

type TextProps = {
  tagName?: AllowedTagNames;
  color?: AllowedColors;
  className?: string;
  children?: any;
};

const createText = ({
  tagName,
  className,
  color,
  children,
}: TextProps & { tagName: AllowedTagNames }) => {
  return h(
    tagName,
    {
      className: classNames(className, {
        [styles.navy]: color === 'navy',
        [styles.white]: color === 'white',
      }),
    },
    children,
  );
};

export const TextRegular = ({ tagName = 'p', color = 'white', className, ...props }: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.regular, className),
    color,
    ...props,
  });
};

export const TextRegularAlt = ({
  tagName = 'p',
  color = 'white',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.regularAlt, className),
    color,
    ...props,
  });
};

export const TextPriority1 = ({
  tagName = 'h1',
  color = 'white',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority1, className),
    color,
    ...props,
  });
};

export const TextSub1 = ({ tagName = 'div', color = 'white', className, ...props }: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.sub1, className),
    color,
    ...props,
  });
};
