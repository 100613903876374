import { h } from 'preact';
import styles from './picture.module.css';

export const Picture = ({ aspectRatio, src }: { aspectRatio: number; src: string }) => (
  <div className={styles.container} style={{ paddingBottom: `${aspectRatio * 100}%` }}>
    <div className={styles.asset}>
      <img src={src} className={styles.img} />
    </div>
  </div>
);
